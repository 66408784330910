/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { SEO, Layout } from "gatsby-theme-catalyst-core"
import { useLocation } from "@reach/router"
import { Fragment } from "react"
import { darken } from "@theme-ui/color"
import {
  SanityContent,
  SanityThemeProvider,
} from "gatsby-theme-catalyst-sanity"

import PageHeader from "../../../components/page-header"

const buyButtonStyles = {
  appearance: "none",
  width: "auto",
  bg: "primary",
  color: "white",
  textAlign: "center",
  lineHeight: "inherit",
  textDecoration: "none",
  fontSize: 2,
  m: 0,
  px: 2,
  py: 1,
  borderColor: "primary",
  borderWidth: "2px",
  borderStyle: "solid",
  borderRadius: "4px",
  letterSpacing: "1px",
  transition: "all 0.3s ease 0s",
  "::after": {
    content: '"\\00A0 \\2192"',
  },
  ":hover": {
    bg: darken("primary", 0.1),
    borderColor: darken("primary", 0.1),
  },
}

const PageTemplate = ({ data }) => {
  const location = useLocation()
  const contact = location.pathname === "/contact"
  const anotherworldispossible = location.pathname === "/anotherworldispossible"
  const result = data.sanityPage
  return (
    <SanityThemeProvider>
      <Layout>
        <SEO title={result.title} />
        <PageHeader
          topImage={result.featuredImage.asset.fluid}
          topImageAlt={result.featuredImage.alt}
          title={result.title}
        />
        {anotherworldispossible && (
          <div
            sx={{
              display: "grid",
              gridGap: [3, 4, null, null, null],
              gridTemplateColumns: ["auto", "auto auto 1fr", null, null, null],
              justifyItems: ["stretch", "start", null, null, null],
            }}
          >
            <Styled.a
              href={"https://bookshop.org/a/1871/9781620978443"}
              target="_blank"
              rel="noopener noreferrer"
              sx={buyButtonStyles}
            >
              Bookshop.org
            </Styled.a>
            <Styled.a
              href={"https://www.barnesandnoble.com/w/?ean=9781620978443"}
              target="_blank"
              rel="noopener noreferrer"
              sx={buyButtonStyles}
            >
              Barnes & Noble
            </Styled.a>
            <Styled.a
              href={
                "https://www.amazon.com/gp/search?keywords=9781620978443&amp;tag=thenewpress-20"
              }
              target="_blank"
              rel="noopener noreferrer"
              sx={buyButtonStyles}
            >
              Amazon
            </Styled.a>
          </div>
        )}

        <SanityContent data={result._rawBody} />

        {contact && (
          <Fragment>
            <iframe
              name="sub stack subscribe"
              src="https://natashahakimizapata.substack.com/embed"
              width="700"
              height="400"
              style={{ border: "1px solid #EEE", background: "white" }}
              frameborder="0"
              scrolling="no"
            ></iframe>
          </Fragment>
        )}
      </Layout>
    </SanityThemeProvider>
  )
}

export default PageTemplate
